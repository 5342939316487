import { Pipe, PipeTransform } from '@angular/core';

import { RoleType } from 'src/app/shared/constants/role-type.constant';
import { User } from 'src/app/shared/models';

@Pipe({
	name: 'assigneesFilter',
	standalone: true,
})
export class AssigneesFilterPipe implements PipeTransform {
	transform(users: User[], loggedInUserId: number, roleId: number): User[] {
		return users.filter(u => roleId === RoleType.system || u.id === loggedInUserId);
	}
}
